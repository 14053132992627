import Acd from './Acd';
import Menu from './Menu';
import Scroll from './Scroll';

$(window).on('load', () => {
  $('.js-slick02')
    .css('visibility', 'visible')
    .slick({
      infinite: true,
      autoplay: true,
      accessibility: true,
      autoplaySpeed: 3000,
      speed: 4000,
      pauseOnHover: true,
      pauseOnFocus: false,
      pauseOnDotsHover: true,
      dots: true,
      arrows: false,
      lazyLoad: 'ondemand',
      fade: true,
      waitForAnimate: false,
      responsive: [{
        breakpoint: 768,
        settings: {
          pauseOnHover: false,
          pauseOnDotsHover: false,
        }
      }]
    });
});

$(function() {
  new Menu();
  new Scroll();
  var acdObj = new Acd();

  $('.js-main-rogo').fadeIn(3000);

  $('.js-slick').slick({
    infinite: true,
    autoplay: true,
    accessibility: true,
    autoplaySpeed: 3000,
    speed: 4000,
    pauseOnHover: true,
    dots: false,
    arrows: false,
    lazyLoad: 'ondemand',
    fade: true,
  });

  //スクロール位置
  let $kv = $('.js-kv-erea');
  if ($kv.length > 0) {

    jQuery(window).on('scroll load resize', () => {
      let top = $(window).scrollTop();
      if ($kv.outerHeight() < top) {
        $('.js-navbox').addClass('is-active');
      } else {
        $('.js-navbox').removeClass('is-active');
      }
    });
  }

  $(".fancybox").fancybox({
    beforeLoad: (e) => {
      document.addEventListener('touchmove', scrollOff, {
        passive: false
      });
    },
    beforeClose: (e) => {
      document.removeEventListener('touchmove', scrollOff, false);
    }
  });
  var scrollOff = function(e) {
    e.preventDefault();
  };

});