class Menu {
  constructor() {
    let $menu = $('.js-navbox');
    $menu.on('click', () => {
      $menu.fadeOut(100);
      $('.js-gnav-back').addClass('is-active');
      $('.js-gnav').slideDown(300);
      $('html').css('overflow', 'hidden');
      document.addEventListener('touchmove', this.prevent, {
        passive: false
      });
    });
    $('.js-menu-close,.js-gnav').on('click', () => {
      $menu.fadeIn(500);
      $('.js-gnav-back').removeClass('is-active');
      $('.js-gnav').slideUp(300);
      $('html').css('overflow', 'auto');
      document.removeEventListener('touchmove', this.prevent, false);
    });
  }
  prevent(e) {
    e.preventDefault();
  }
}

export default Menu;