class Acd {

  constructor() {

    $('.js-ac-parent').on('click', (e) => {
      var windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        if ($(e.currentTarget).hasClass('is-active')) {
          $(e.currentTarget).find('.js-switch-icon').text('詳しく見る');
          $(e.currentTarget).removeClass('is-active');
          $(e.currentTarget).closest('.js-ac-parent').find('.js-ac-content').stop(true, true).slideUp(250);
        } else {
          $(e.currentTarget).find('.js-switch-icon').text('閉じる');
          $(e.currentTarget).addClass('is-active');
          $(e.currentTarget).closest('.js-ac-parent').find('.js-ac-content').stop(true, true).slideDown(250);
        }
      }
    });
  }
}

export default Acd;